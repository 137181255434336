import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Header } from '../components/atoms';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    role: '',
    message: '',
  });

  function handleChange(event, field) {
    setForm({ ...form, [field]: event.target.value });
  }

  return (
    <Layout
      title={t('Request trial account')}
      description={t(
        "We'll be in touch within 24 hours to set up a personalized trial for you."
      )}
    >
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <svg
            className="text-gray_2 absolute left-full transform translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div className="text-center">
            <Header>
              <Trans>Request trial account</Trans>
            </Header>
            <p className="mt-4 text-lg leading-6 text-gray_4">
              <Trans>
                We'll be in touch within 24 hours to set up a personalized trial
                for you.
              </Trans>
            </p>
          </div>
          <div className="mt-12">
            <form
              data-netlify="true"
              netlify-honeypot="bot-field"
              name={`request-trial-account-${i18n.language}`}
              action="/success"
              method="POST"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <input
                type="hidden"
                name="form-name"
                value={`request-trial-account-${i18n.language}`}
              />
              <input type="hidden" name="bot-field" />
              <div>
                <label
                  htmlFor={i18n.language === 'en' ? 'firstname' : 'lastname'}
                  className="block text-sm font-medium text-gray_4"
                >
                  {i18n.language === 'en' ? (
                    <Trans>First name</Trans>
                  ) : (
                    <Trans>Last name</Trans>
                  )}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name={i18n.language === 'en' ? 'firstname' : 'lastname'}
                    id={i18n.language === 'en' ? 'firstname' : 'lastname'}
                    autoComplete={
                      i18n.language === 'en' ? 'given-name' : 'family-name'
                    }
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) =>
                      handleChange(
                        event,
                        i18n.language === 'en' ? 'firstname' : 'lastname'
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor={i18n.language === 'en' ? 'lastname' : 'firstname'}
                  className="block text-sm font-medium text-gray_4"
                >
                  {i18n.language === 'en' ? (
                    <Trans>Last name</Trans>
                  ) : (
                    <Trans>First name</Trans>
                  )}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name={i18n.language === 'en' ? 'lastname' : 'firstname'}
                    id={i18n.language === 'en' ? 'lastname' : 'firstname'}
                    autoComplete={
                      i18n.language === 'en' ? 'family-name' : 'given-name'
                    }
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) =>
                      handleChange(
                        event,
                        i18n.language === 'en' ? 'lastname' : 'firstname'
                      )
                    }
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray_4"
                >
                  <Trans>Company</Trans>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="organization"
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) => handleChange(event, 'company')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray_4"
                >
                  <Trans>Work email address</Trans>
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) => handleChange(event, 'email')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray_4"
                >
                  <Trans>Role</Trans>
                </label>
                <div className="mt-1">
                  <input
                    id="role"
                    name="role"
                    type="text"
                    autoComplete="organization-title"
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) => handleChange(event, 'role')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray_4"
                >
                  <Trans>How do you plan on using Amplified?</Trans>
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    defaultValue={''}
                    onChange={(event) => handleChange(event, 'message')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className={classNames(
                    Object.values(form).every((val) => !!val)
                      ? 'bg-pastel_red hover:bg-pastel_red_120'
                      : 'bg-gray_3',
                    'rounded-md w-full px-8 py-4 flex items-center justify-center text-lg leading-6 font-medium text-white md:px-10 w-full'
                  )}
                  disabled={Object.values(form).every((val) => !val)}
                >
                  <Trans>Submit</Trans>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
